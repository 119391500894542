.BBFormContainer{
    background: linear-gradient(#FFFFFF, #dbdbdb) !important;
}

.BBFormErrorBlock{
    background-color: transparent !important;
    border: none !important;
}

.BBFormSectionHeading{
    background-color: #067f93 !important;
    border: none !important;
}

.BBFormSubmitbutton{
    background-color: #FFD41C !important;
    color: black !important;
    border: none !important;
}
.BBFormSummaryTotal{
    position: absolute;
    left: 0;
    /* display: none !important; */
}
.BBFormButtonRow .BBFormFieldContainer:last-of-type{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#bbox-root{
    overflow-x: hidden
}
#close-button{
    padding: 0.7em 2em;
    border-radius: 6px;
    outline: none;
    border: none;
    font-weight: bold;
    box-shadow: 0px 0px 4px #33333333 ;
    background:rgb(59, 59, 59);
    font-size: 1.4em;
    color: white;
}

.black-close{
    font-size: 3em;
    color: white;
    cursor: pointer;
    height: 1em;
    width: 1em;
    background-color: #FFFFFF77;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.5em 0em;

}

@media screen and (max-width: 600px) {
    #close-button{
        margin-left: 1em;
        min-width: 42vw;
    }
    .BBFormSummaryTotal{
        transform: translate(20px, -60px);
        display: flex;
        justify-content: flex-end;
        visibility: hidden;

    }
    
    
}

@media screen and (min-width: 601px) and (max-width: 700px) {

  
}

