

h5{
    color: #212529;
}



.input-header-title{
    font-size: 1.25rem;
    margin: 0;
}


  

@media screen and(min-width: 576px) {
    .modal-content{
        padding: 3rem;
    }
    
}


.close{
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    color: #777777;
    appearance: none;
    background-color: transparent;
    border: 0;
}

.form-container{

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    flex: 1 1 auto;
    padding: 1rem;
    transition: all 0.3s ease-in-out;
}

.form-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.form-footer-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0.25rem;
    padding: 1rem;
}


.form-footer-close-button{
    color: #212529;
    background-color: rgb(197, 197, 197);
    border-color: rgb(194, 194, 194);;
    margin: 0.25rem;
}

.form-footer-next-button{
    color: black;
    background-color: #FFD41C;
    border-color: #FFD41C;
    margin: 0.25rem;
}

.modal-divider{
    border: 0.5px solid rgba(0,0,0,0.1);
    border-radius: 0.3rem;
}

.form-disclaimer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}


.form-checkbox{
    transform: translateY(-10px);
}

.form-select-group{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


.modal-btn {
    padding: 0.5em 2em;
    border-radius: 3px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 4px #33333333 ;
}

.consent-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px;
    border-radius: 10px;
}

